<template>
  <v-container class="mother">
    <v-row class="topObj mt-0" style="height: 60vh">
      <!-- MAIN BUTTONS -->

      <v-col class="mainButtons pr-0 pl-4 ml-0 mr-0 mt-0" md="2" lg="2">
        <v-card
          elevation="2"
          color="grey lighten-3"
          style="height: 50vh; width: 12vw"
        >
          <v-row class="justify-center">
            <v-select
              class="ml-6 mr-6 mt-2"
              background-color="#FAFAFA"
              :items="floorsToChoose"
              v-model="selectedFloor"
              @change="blueprintByFloor()"
              :label="$t('button.selectFloor')"
              dense
              hide-details
              outlined
            ></v-select>
          </v-row>
          <v-row class="justify-center">
            <v-select
              class="ml-6 mr-6 mt-2"
              background-color="#FAFAFA"
              v-model="selectedDevice"
              :items="devicesToSelect"
              @change="highlightDeviceSelected()"
              :label="$t('button.chooseDevice')"
              dense
              hide-details
              outlined
            ></v-select>
          </v-row>
          <v-row class="justify-center">
            <v-btn
              width="150"
              class="mt-4"
              elevation="2"
              @click="saveSensorPosition"
              >{{ $t("button.saveSensor") }}</v-btn
            >
          </v-row>
          <v-row class="justify-center">
            <v-btn width="150" class="mt-2" elevation="2" @click="drawGrid">{{
              $t("button.drawGrid")
            }}</v-btn>
          </v-row>
          <v-row class="justify-center">
            <v-btn
              width="150"
              class="mt-2"
              elevation="2"
              @click="$refs.canvaObj.refreshDisplay()"
              >{{ $t("button.refresh") }}</v-btn
            >
          </v-row>
          <v-row class="justify-center">
            <v-btn
              width="150"
              class="mt-2"
              elevation="2"
              @click="deleteBlueprint"
              >{{ $t("button.deleteBlueprint") }}</v-btn
            >
          </v-row>
          <v-row class="justify-center">
            <v-btn
              width="150"
              class="mt-2"
              elevation="2"
              @click="navigateTo({ name: 'lobby' })"
            >
              {{ $t("button.returnToLobby") }}
            </v-btn>
          </v-row>
        </v-card>
      </v-col>

      <!-- CANVA OBJ-->

      <v-col class="canvaBorder pa-0 pr-4 pl-4 ml-0 mt-0" md="10" lg="10">
        <v-card elevation="2" color="grey lighten-3" style="height: 50vh">
          <canva-obj
            :path="path"
            :blueprintId="blueprintId"
            ref="canvaObj"
            class="canvaDiv"
          ></canva-obj>

          <!-- <v-row align="center" justify="center" class="mt-1">
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="xCoord"
                label="X Coordinate"
                outlined
                dense
              ></v-text-field> </v-col
            ><v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="yCoord"
                label="Y Coordinate"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row> -->
          <div class="red--text" v-html="displayError" />
          <div class="green--text" v-html="displaySuccess" />
        </v-card>
      </v-col>
    </v-row>

    <div class="align-center text-center mt-8 mx-6">
      <v-card elevation="2" style="margin: auto" max-width="1000">
        <v-toolbar flat dense class="blue darken-2" dark>
          <v-toolbar-title>{{ $t("tables.uploadFile") }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-2">
          <v-flex class="md2 ml-6">
            <v-container fluid>
              <!--display: noone is hiding the input and then we attribute it a ref named fileInput-->
              <input type="file" @change="onFileSelected" ref="fileInput" />
              <!--$refs is a vue object that manages all the refs assigned. 
              To the @click is passed the input ref and the ".click" proxies the click from the button to the input -->
              <!-- <v-btn
                class="ml-10 mt-3 mr-6"
                elevation="2"
                @click="$refs.fileInput.click()"
              >
                {{ $t("button.selectFile") }}
                ></v-btn
              > -->
            </v-container>
          </v-flex>

          <v-spacer></v-spacer>

          <v-flex class="md4 ml-16">
            <v-text-field
              v-model="floorName"
              :label="$t('global.floorName')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>

          <br />
          <v-btn class="ml-10 mt-3 mr-6" elevation="2" @click="onUpload">
            {{ $t("button.upload") }}
            <v-progress-circular
              v-if="uploadFinish"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="deviceUploadError" />
        <div class="black--text" v-html="deviceUploadSuccess" />
      </v-card>
    </div>
  </v-container>
</template>

<script>
import HelperMethods from "@/utilities/HelperMethods";
import BlueprintService from "@/services/BlueprintService";
import CanvaObj from "@/components/CanvaObj";

export default {
  components: { CanvaObj },
  data() {
    return {
      // Sensor Variables
      sensorPositionObj: {},
      sensorPositionBlueprint: {
        deviceId: null,
        xCoord: null,
        yCoord: null,
        refBlueprint: null,
      },

      // Device Variables
      devices: [],
      devicesToSelect: [],
      selectedDevice: [],

      // Blueprint Variables
      floorsToChoose: [],
      selectedFloor: [],
      floorName: null,
      blueprintsObj: null,
      path: null,
      blueprintId: null,

      // Functionality Variables
      colors: ["purple", "black", "green"],
      selectedColor: null,
      ctx: null,
      canvas: null,
      xCoord: null,
      yCoord: null,
      selectedFile: null,

      // Variables to display Messages on the webpage
      deviceUploadError: null,
      deviceUploadSuccess: null,
      displayError: null,
      displaySuccess: null,
      uploadFinish: null,

      xPosition: null,
      yPosition: null,
    };
  },

  methods: {
    highlightDeviceSelected() {
      let deviceObj = HelperMethods.getDeviceObjByName(
        this.devices,
        this.selectedDevice
      );
      let refBlueprint = HelperMethods.getBlueprintIdByFloorName(
        this.blueprintsObj,
        this.selectedFloor
      );

      // Push into the array deviceId to hold the id of the selected device
      // because the method "highlightDevicesById" only takes an array of devices
      let device = [];
      device.push({id: deviceObj.id, status: true});

      this.$refs.canvaObj.highlightDevicesById(device, refBlueprint, );
    },

    async deleteBlueprint() {
      try {
        let path = HelperMethods.getBlueprintPathByFloorName(
          this.blueprintsObj,
          this.selectedFloor
        );
        await BlueprintService.deleteBlueprint(
          this.selectedFloor,
          this.$store.getters.groupId,
          path
        );
        this.getBlueprints();
      } catch (err) {
        console.log("deleteBlueprint Error: ", err);
      }
    },

    blueprintByFloor() {
      let path = HelperMethods.getBlueprintPathByFloorName(
        this.blueprintsObj,
        this.selectedFloor
      );
      let blueprintId = HelperMethods.getBlueprintIdByFloorName(
        this.blueprintsObj,
        this.selectedFloor
      );
      this.path = path;
      this.blueprintId = blueprintId;
    },

    storeCoords(e) {
      if (this.selectedDevice.length === 0) {
        return (this.displayError = "Choose a device first.");
      }
      this.xCoord = e.offsetX;
      this.yCoord = e.offsetY;
      this.drawSensor();
    },

    drawSensor() {
      this.displayError = null;

      let position = this.$refs.canvaObj.drawSensor(this.xCoord, this.yCoord);
      console.log("position: ", position);

      this.xPosition = position[0];
      this.yPosition = position[1];

      this.displaySuccess =
        "Sensor position memorized, press save sensor button to save it.";
    },

    drawGrid() {
      this.$refs.canvaObj.drawGrid();
    },

    async saveSensorPosition() {
      let self = this;
      let device = HelperMethods.getDeviceObjByName(
        this.devices,
        this.selectedDevice
      );
      this.sensorPositionObj.deviceId = device.id;
      this.sensorPositionObj.xCoord = this.xPosition;
      this.sensorPositionObj.yCoord = this.yPosition;
      this.sensorPositionObj.refBlueprint = this.blueprintId;

      let sensorPositionObj = this.sensorPositionObj;

      try {
        await BlueprintService.saveSensorPosition(
          sensorPositionObj
        );
        self.displayError = null;
        self.displaySuccess = "Sensor position saved.";
        self.$refs.canvaObj.refreshDisplay();
      } catch (err) {
        console.log("error", err);
        self.displaySuccess = null;
        self.displayError =
          "An error occurred while saving your sensor position. Be sure to have a blueprint selected.";
      }
    },

    async getBlueprints() {
      let response = await BlueprintService.getGroupBlueprints(
        this.$store.getters.groupId
      );
      this.$store.dispatch("setBlueprints", response.data);
      this.blueprintsObj = response.data;
      this.floorsToChoose = HelperMethods.getFloorNamesFromBlueprintObj(
        this.blueprintsObj
      );
    },

    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      if (this.selectedFile === null) {
        this.deviceUploadError = "A file must be selected first.";
      } else if (this.floorName.length > 16) {
        this.deviceUploadError = "Floor name must not exceed 16 characters.";
        return;
      } 

      const fd = new FormData();
      let groupId = this.$store.getters.groupId;
      let name = groupId + "-" + this.selectedFile.name;
      let blueprintName = this.floorName;
      let blueprintPath = name;
      let group = groupId;

      // FormData.append takes a key/value pair. Optionally can also take a filename. "formData.append(name, value, filename)"
      fd.append("name", blueprintName);
      fd.append("path", blueprintPath);
      fd.append("refGroup", group);
      fd.append("blueprintFile", this.selectedFile, name);

      try {
        this.uploadFinish = true;
        let response = await BlueprintService.uploadFile(fd);
        setTimeout(() => {
          this.deviceUploadError = null;
          this.deviceUploadSuccess = response.data.message;
          this.uploadFinish = false;
        }, 2000);
      } catch (err) {
        console.log("onUpload Error: ", err);
        this.uploadFinish = false;
        this.deviceUploadSuccess = null;
        this.deviceUploadError = "File uploading failed.";
      }

      this.getBlueprints();
    },

    navigateTo(route) {
      this.$router.push(route);
    },
  },

  beforeMount() {
    // Get all floor blueprints, from the store, uploaded by the group to make them available to be displayed
    this.blueprintsObj = this.$store.getters.blueprints;

    if (this.blueprintsObj.length != 0) {
      // Populate the select button "floorsToChoose" to have all the floor names found on the blueprintsObj
      this.floorsToChoose = HelperMethods.getFloorNamesFromBlueprintObj(
        this.blueprintsObj
      );
      // Set default blueprint to display
      this.path = this.blueprintsObj[0].path;
      // Set default selectedFloor value
      this.selectedFloor = this.blueprintsObj[0].name;
      // Set default blueprintId value
      this.blueprintId = Number(this.blueprintsObj[0].id);
    }
  },

  async mounted() {
    const canvaDiv = document.querySelector(".canvaDiv");

    // Setting up the event listener that will listen for mouse clicks
    canvaDiv.addEventListener("click", (e) => this.storeCoords(e));

    // Get group device objects from the store
    this.devices = this.$store.getters.devices;

    // Storing the names of the devices on an array to have them on hand to pass to the "Choose Device button"
    for (let i = 0; i < this.devices.length; i++) {
      this.devicesToSelect.push(this.devices[i].name);
    }
  },
};
</script>

<style>
body {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  min-height: 100vh;
  margin: 0;
}

#canvas {
  background: #f0f0f0;
  /* border: 1px solid yellowgreen; */
}
/* .mother {
  height: 100vh;
  width: 100vw;
  border: 1px solid yellowgreen;
} */
.topObj {
  height: 45vh;
  /* border: 1px solid red; */
}
/* .canvaBorder {
  border: 1px solid red;
} */
/* .mainButtons {
  border: 1px solid blueviolet;
} */
.utilityButtons {
  border-radius: 20px;
  /* border: 1px solid blueviolet; */
}
/* .transparent {
  background-color: white !important;
  opacity: 1;
  border-color: transparent !important;
} */
</style>
